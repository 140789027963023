// extracted by mini-css-extract-plugin
export var articleWrapper = "m_pF";
export var articleText = "m_pG d_dv d_cs";
export var header = "m_pH d_Z d_w";
export var headerImageWrapper = "m_pJ d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "m_pK d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "m_pL d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "m_pM d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "m_pN d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "m_mv d_w";
export var dateTag = "m_pP d_cf d_cr d_dq d_w";
export var icon1 = "m_pQ d_cP";
export var icon2 = "m_pR d_cP d_cJ";
export var tagBtn = "m_pS d_b1 d_cD d_dp d_dB";
export var headerText = "m_pT d_w d_cf d_cs d_bW";
export var center = "m_pV d_dw";
export var videoIframeStyle = "m_pW d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "m_pX d_cs d_Z";
export var articleImageWrapperIcon = "m_pY d_cs d_Z";
export var articleRow = "m_pZ d_bD";
export var quoteWrapper = "m_gQ d_bC d_bP d_cs";
export var quoteBar = "m_p0 d_H";
export var quoteText = "m_p1";
export var authorBox = "m_p2 d_w";
export var authorRow = "m_p3 d_bD d_bW d_cy";
export var separator = "m_p4 d_w";
export var line = "m_fk d_w d_fk d_cY d_cy";
export var authorImage = "m_ln d_b7 d_Z d_w d_ct";
export var authorText = "m_p5 d_ch d_ct";
export var masonryImageWrapper = "m_p6";
export var bottomSeparator = "m_p7 d_w d_cy";
export var linksWrapper = "m_p8 d_ch d_dv";
export var comments = "m_p9 d_bD d_cy";
export var sharing = "m_qb d_bz d_bD d_bJ";
export var shareText = "m_qc d_w d_dw";
export var icon = "m_qd";
export var customRow = "m_pD d_bD d_Z";
export var text = "m_qf";
export var SubtitleSmall = "m_qg";
export var SubtitleNormal = "m_qh";
export var SubtitleLarge = "m_qj";