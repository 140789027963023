// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "x_tx d_gS d_cw d_dv";
export var quoteParagraphCenter = "x_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "x_gV d_gV d_cw d_dx";
export var quoteRowLeft = "x_ty d_bG";
export var quoteRowCenter = "x_tz d_bD";
export var quoteRowRight = "x_tB d_bH";
export var quoteWrapper = "x_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "x_gR d_gR";
export var quoteExceptionSmall = "x_rK s_rK";
export var quoteExceptionNormal = "x_rL s_rL";
export var quoteExceptionLarge = "x_rM s_rM";
export var quoteAuthorExceptionSmall = "x_rN s_rN";
export var quoteAuthorExceptionNormal = "x_rP s_rP";
export var quoteAuthorExceptionLarge = "x_rQ s_rQ";