// extracted by mini-css-extract-plugin
export var dark = "v_sz";
export var darkcookie = "v_sB";
export var tintedcookie = "v_sC";
export var regularcookie = "v_sD";
export var darkbase = "v_sF";
export var tintedbase = "v_sG";
export var regularbase = "v_sH";
export var darkraw = "v_sJ";
export var tintedraw = "v_sK";
export var regularraw = "v_sL";
export var darkchick = "v_sM";
export var tintedchick = "v_sN";
export var regularchick = "v_sP";
export var darkherbarium = "v_sQ";
export var tintedherbarium = "v_sR";
export var regularherbarium = "v_sS";
export var darkholiday = "v_sT";
export var tintedholiday = "v_sV";
export var regularholiday = "v_sW";
export var darkoffline = "v_sX";
export var tintedoffline = "v_sY";
export var regularoffline = "v_sZ";
export var darkorchid = "v_s0";
export var tintedorchid = "v_s1";
export var regularorchid = "v_s2";
export var darkpro = "v_s3";
export var tintedpro = "v_s4";
export var regularpro = "v_s5";
export var darkrose = "v_s6";
export var tintedrose = "v_s7";
export var regularrose = "v_s8";
export var darktimes = "v_s9";
export var tintedtimes = "v_tb";
export var regulartimes = "v_tc";
export var darkwagon = "v_td";
export var tintedwagon = "v_tf";
export var regularwagon = "v_tg";