// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_qz d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_qB d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_qC d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_qD d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_qF d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_qG d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_qH d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_qJ s_qJ";
export var heroExceptionNormal = "q_qK s_qK";
export var heroExceptionLarge = "q_qL s_qL";
export var Title1Small = "q_qM s_qM s_qV s_qW";
export var Title1Normal = "q_qN s_qN s_qV s_qX";
export var Title1Large = "q_qP s_qP s_qV s_qY";
export var BodySmall = "q_qQ s_qQ s_qV s_rc";
export var BodyNormal = "q_qR s_qR s_qV s_rd";
export var BodyLarge = "q_qS s_qS s_qV s_rf";