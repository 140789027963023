// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "t_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "t_fR d_fR d_bz d_bJ";
export var menuDesign6 = "t_r2 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "t_r3 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "t_r4 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "t_r5 d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "t_r6 d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "t_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "t_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "t_r7";
export var navbarItem = "t_nb d_bx";
export var navbarLogoItemWrapper = "t_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "t_r8 d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "t_r9 d_gd d_by d_Z d_bs";
export var burgerToggle = "t_sb d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "t_sc d_gd d_by d_Z d_bs";
export var burgerInput = "t_sd d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "t_sf d_f3 d_w d_H";
export var burgerLine = "t_sg d_f1";
export var burgerMenuDesign6 = "t_sh d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "t_sj d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "t_sk d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "t_sl d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "t_sm d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "t_sn d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "t_sp d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "t_sq d_bC d_bP";
export var compact = "t_sr";
export var navDivided = "t_ss";
export var staticBurger = "t_st";
export var menu = "t_sv";
export var navbarDividedLogo = "t_sw";
export var nav = "t_sx";
export var fixed = "t_sy";