// extracted by mini-css-extract-plugin
export var lbContainerOuter = "C_vg";
export var lbContainerInner = "C_vh";
export var movingForwards = "C_vj";
export var movingForwardsOther = "C_vk";
export var movingBackwards = "C_vl";
export var movingBackwardsOther = "C_vm";
export var lbImage = "C_vn";
export var lbOtherImage = "C_vp";
export var prevButton = "C_vq";
export var nextButton = "C_vr";
export var closing = "C_vs";
export var appear = "C_vt";